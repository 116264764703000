
              @import "@/assets/css/variables.scss";
            














































































































// @import '@/assets/css/pages/register/error.scss';
.error_page {
  height: 100%;
  display: flex;
  align-items: center;

  .form_wrapper {
    text-align: center;
    flex: 1;
    margin-top: -80px;

    @include screen-mobile {
      margin-top: -24px;
    }

    p.title {
      font-weight: 700;
      font-size: 24px;
      color: #09090D;
      line-height: 36px;
      margin-bottom: 24px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: $text-secondary;

      a {
        color: $text-link;
      }

      &.error_code {
        margin-top: 24px;
      }
    }

    .btn {
      display: inline-block;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      color: $white;
      border-radius: 8px;
      background: $secondary;
      margin-top: 40px;
    }

    a.btn {
      line-height: 40px;
    }

    .forgot_pass {
      font-size: 14px;
      line-height: 22px;
      color: $primary;
      cursor: pointer;
      text-align: center;
      margin-top: 24px;
    }
  }
}
